import React from 'react';

function Bookeeping() {
    return (
        <div className= "bookeeping">
            <h1>Bookeeping</h1>
            <p>This will house a bookkeeping app some day, maybe...</p>
        </div>
    )
}

export default Bookeeping;