import React from 'react';
import keycloakService from "../../services/authentication/KeycloakService";
import './Header.scss';

function Header() {
    const keycloak =  keycloakService.getKeycloakInstance();
    const isLoggedIn: boolean = keycloak != null && keycloak.authenticated === true;
    const userName: string = keycloak?.idTokenParsed?.preferred_username;

    console.log("Header: logged in: " + isLoggedIn);

    const login = () => {
        console.log('attempting login');
        if (keycloak) {
            keycloak.login()
                .catch(() => console.error("keycloak login failed."));
        } else {
            console.error("Tried to login before keycloak was available!");
        }
    }
    const logout = () => {
        if (keycloak) {
            keycloak.logout()
                .catch(() => console.error("keycloak logout failed."));
        } else {
            console.error("Tried to logout before keycloak was available!");
        }
    }

    const showUser = isLoggedIn ?
        (<div>
            <div>User: {userName}</div>
            <button onClick={logout}>Log out</button>
        </div>)
        :
        (<button onClick={login}>Login with Keycloak</button>);

    return (
        <div className="header">
            <div className="logo">
                <img className="center-fit" src="greiner_logo_big.svg" alt="Greiner logo"/>
            </div>
            {showUser}
        </div>
    )
}

export default Header;