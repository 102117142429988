import React from 'react';

const About = () => {

    return (
        <p>This is my attempt to create a web application using react. As you can see it is very much a work in
            progress...</p>
    );
};

export default About;