import React, {FC, useEffect, useState} from 'react';

import './App.scss';
import Navbar from "./components/sidebar/Navbar";
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import Header from "./components/header/Header";
import Home from './pages/Home';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Private from './pages/Private';
import Bookkeeping from "./pages/Bookkeeping";
import PrivateRoute from "./components/private/PrivateRoute";
import Keycloak from "keycloak-js";
import KeycloakService from "./services/authentication/KeycloakService";
import About from "./pages/About";
import NotFound from "./pages/NotFound";

const eventLogger = (event: any, error:any) => {
    console.log('onKeycloakEvent', event, error);
};

const tokenLogger = (tokens:any) => {
    console.log('onKeycloakTokens', tokens);
};

const App: FC = () => {
    const [keycloak, setKeycloak] = useState<Keycloak | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const keycloakService = KeycloakService.getInstance();
        initKeycloak(keycloakService);
    }, []);

    function initKeycloak(keycloakService: KeycloakService) {
        keycloakService.initializeKeycloak().then((keycloak) => {
            // You can now use the initialized Keycloak instance
            console.log('Keycloak initialized:', keycloak);
            setKeycloak(keycloak);
            setLoading(false);
        }).catch((reason) => {
            console.log("Exception occurred initializing keycloak")
        });
    }

    // Render loading state while keycloak is initializing
    if (loading) {
        return <div className="kcProvider">Loading...</div>;
    }

    if (!keycloak) {
        // Handle the case where initialization fails
        return <div>Error initializing Keycloak.</div>;
    }

    if (keycloak) {
        console.log("Keycloak has been initialized; loading application");
    }

    return (
        <div className="App">
                    <BrowserRouter >
                        <Header />
                        <Navbar />
                        <Routes >
                            <Route path='/' element={<Home />}/>
                            <Route path='/login/*' element={<Login/>}/>
                            <Route path='/logout/*' element={<Logout/>}/>

                            <Route path="/private" element={<PrivateRoute> <Private/> </PrivateRoute>} />
                            <Route path="/bookkeeping" element={<PrivateRoute> <Bookkeeping/> </PrivateRoute>} />

                            <Route path='/about/*' element={<About/>}/>

                            <Route path={"/404"} element={  <NotFound/> }/>
                            {/*// take care of redirects for all paths that doesn't match the defined*/}
                            <Route path={"*"} element={ <Navigate replace to={ "/index.html" }/> }/>
                        </Routes>
                    </BrowserRouter>
        </div>
    )
}

export default App;
