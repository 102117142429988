import React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import keycloakService from "../../services/authentication/KeycloakService";

const PrivateRoute = (props: { children: React.ReactNode }): JSX.Element => {
    const { children } = props
    const keycloak =  keycloakService.getKeycloakInstance();
    const isLoggedIn: boolean = keycloak != null && keycloak.authenticated === true;
    console.log(keycloak);
    console.log("keycloak auth? " + keycloak?.authenticated);
    const location = useLocation();
    return isLoggedIn ? (
        <>{children}</>
    ) : (
        <Navigate
            replace={true}
            to="/login"
            state={{ from: `${location.pathname}${location.search}` }}
        />
    )
}
export default PrivateRoute;