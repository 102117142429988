import React, {useState} from 'react';
import { FaBars } from "react-icons/fa6";
import {IoIosClose, IoIosLogIn, IoIosLogOut} from "react-icons/io";
import { Link } from 'react-router-dom';
import  SidebarData from "./sidebarData";
import './Navbar.scss';
import {IconContext} from "react-icons";
import keycloakService from "../../services/authentication/KeycloakService";

function Navbar() {
    const [sidebar,setSidebar]    = useState(false);
    const keycloak =  keycloakService.getKeycloakInstance();
    const isLoggedIn: boolean = keycloak != null && keycloak.authenticated === true;

    const showSidebar = () => {
        return setSidebar(!sidebar);
    };

    const fullSidebarData = isLoggedIn ? SidebarData.concat(
        {
            "index": 5,
            "title": "Logout",
            "path": "/logout",
            "cName": "nav-text",
            "icon": <IoIosLogOut />,
        }) : SidebarData.concat(
        {
            "index": 5,
            "title": "Login",
            "path": "/login",
            "cName": "nav-text",
            "icon": <IoIosLogIn />,
        });

    return (
        <>
            <IconContext.Provider value={{color: '#fff'}}>
                <div className="navbar">
                <Link to="#" className="menu-bars">
                    <FaBars onClick={showSidebar}/>
                </Link>
                </div>
                <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <ul className="nav-menu-items" onClick={showSidebar}>
                        <li className="navbar-toggle">
                            <Link to="#" className="menu-bars">
                                <IoIosClose display={sidebar ? "block" : "none"} />
                            </Link>
                        </li>
                        {
                            fullSidebarData.map(item => {
                                return (
                                    <li key={item.index} className={item.cName}>
                                        <Link to={item.path}>
                                            {item.icon}
                                            <span>{item.title}</span>
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </nav>
            </IconContext.Provider>
        </>
    )
}

export default Navbar