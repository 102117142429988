import Keycloak from 'keycloak-js';
import keycloakConfig from './keycloak-config';

class KeycloakService {
    private static instance: KeycloakService;
    private keycloak: Keycloak | null = null;

    private constructor() {
        // Private constructor to prevent instantiation outside this class
    }

    static getInstance(): KeycloakService {
        if (!KeycloakService.instance) {
            KeycloakService.instance = new KeycloakService();
        }
        return KeycloakService.instance;
    }

    static getKeycloakInstance(): Keycloak | null {
        return this.instance.keycloak;
    }

    async initializeKeycloak(): Promise<Keycloak> {
        if (!this.keycloak) {
            this.keycloak = new Keycloak(keycloakConfig);
            await this.keycloak.init({
                onLoad: 'check-sso',
                // onLoad: 'login-required',
                silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            }).catch((reason: boolean) => {
                console.log("Initialization of keycloak failed");
            });
        }
        return this.keycloak;
    }
}

export default KeycloakService;