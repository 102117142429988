import React from 'react';
import { AiFillHome } from "react-icons/ai";
import { TbFileSpreadsheet } from "react-icons/tb";
import { RiGitRepositoryPrivateLine } from "react-icons/ri";
import {IoIosLogIn, IoIosLogOut} from "react-icons/io";
import {FaRegQuestionCircle} from "react-icons/fa";

interface SidebarProps {
    path: string;
    cName: string;
    icon: React.JSX.Element;
    title: string,
    index: number,
}

const SidebarData:(SidebarProps)[] = [
    {
        "index": 1,
        "title": "Home",
        "path": "/",
        "cName": "nav-text",
        "icon": <AiFillHome />,
    },
    {
        "index": 2,
        "title": "Private",
        "path": "/private",
        "cName": "nav-text",
        "icon": <RiGitRepositoryPrivateLine />,
    },
    {
        "index": 3,
        "title": "Bookkeeping",
        "path": "/bookkeeping",
        "cName": "nav-text",
        "icon": <TbFileSpreadsheet />
    },
    {
        "index": 4,
        "title": "About",
        "path": "/about",
        "cName": "nav-text",
        "icon": <FaRegQuestionCircle />
    },
];

export default SidebarData;