import React, {useState} from 'react';
import About from "../components/about/about";

function Home() {
    return (
        <div className="home">
            <h1>Home</h1>
            <p>Greiner development page</p>
            <About/>
        </div>
    )
}

export default Home;